<template>
  <view-card-template :loaded="loaded">
    <template #cardTitle>
      Ballots
    </template>

    <template #cardBody>
      
      <v-data-table
        :headers="headers"
        :items="ballotsFiltered"
        :items-per-page="15"
        class="expanding-v-data-table"
        dense
      >
        <template #top>
          <v-divider/>
          <v-row class="mx-1 mb-4 flex-grow-0">
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="titleSearch"
                label="Filter by Title"
                hide-details
                clearable
              />
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-combobox
                v-model="selectedSport"
                :items="mappedSports"
                label="Filter by Sport"
                hide-details
                clearable
              />
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-combobox
                v-model="selectedSeason"
                :items="mappedSeasons"
                label="Filter by Season"
                hide-details
                clearable
              />
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-combobox
                v-model="selectedType"
                :items="mappedTypes"
                label="Filter by Type"
                hide-details
                clearable
              />
            </v-col>
          </v-row>
        </template>

        <template #item="{ item }">
          <tr>
            <td class="font-weight-bold">{{ item.title }}</td>
            <td>
              <div class="d-flex flex-column">
                <div>{{ item.sportName }}</div>
                <div class="grey--text">
                  {{ [item.season, item.division].filter(_ => _ != null && _.length > 0).join(" | ") }}
                </div>
              </div>
            </td>
            <td>
              {{ item.ballotTypeName }}
            </td>
            <td>
              <div
                v-if="item.currentPhase.name == 'Nomination'"
                class="d-flex flex-column align-center mt-1"
              >
                <v-btn
                  @click="clickNominate(item)"
                  :to="`/voting/select-award/${item.id}`"
                  class="white--text"
                  color="amber darken-2"
                  block
                >
                  Nominate
                </v-btn>
                <div>
                  Due by {{ formatDate(item.expirationDate) }}
                </div>
              </div>
              <div
                v-if="item.currentPhase.name == 'Voting'"
                class="d-flex flex-column align-center mt-1"
              >
                <v-btn
                  @click="clickVote(item)"
                  :to="`/voting/vote/${item.id}`"
                  class="white--text"
                  color="green darken-2"
                  block
                >
                  {{ item.userAlreadyVoted ? "Recast" : "Vote" }}
                </v-btn>
                <div>
                  Due by {{ formatDate(item.expirationDate) }}
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #footer="{ props }">
          <v-divider v-if="props.pagination.itemsLength > 0"/>
          <div class="expanding-v-data-table--footer"></div>
        </template>
      </v-data-table>
    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from "@/data/controllers"

import moment from "moment"

export default {
  name: "Voting",

  components: {
    ViewCardTemplate,
  },

  props: {
    //
  },

  data: () => ({
    loaded: false,
    
    ballots: [],

    titleSearch: null,
    selectedSport: null,
    selectedSeason: null,
    selectedType: null,

    headers: [
      {
        text: "Title",
        align: "start",
        sortable: true,
        value: "title",
        width: "50%",
      },
      {
        text: "Sport",
        // align: "start",
        sortable: true,
        value: "sportName",
        width: "15%",
      },
      {
        text: "Type",
        // align: "start",
        sortable: true,
        value: "ballotTypeName",
        width: "15%",
      },
      {
        text: "",
        // align: "start",
        sortable: true,
        value: "expirationDate",
        width: "20%",
      },
    ],
  }),

  computed: {
    mappedSports() {
      return this.ballots
        .map(ballot => ballot.sportName)
        .sort((a, b) => a.localeCompare(b))
    },

    mappedSeasons() {
      return this.ballots
        .map(ballot => ballot.season)
        .sort((a, b) => b.localeCompare(a))
    },

    mappedTypes() {
      return this.ballots
        .map(ballot => ballot.ballotTypeName)
        .sort((a, b) => a.localeCompare(b))
    },

    ballotsFiltered() {
      return this.ballots
        .filter(ballot => this.titleSearch && this.titleSearch.length ? ballot.title.toLocaleLowerCase().includes(this.titleSearch.toLocaleLowerCase()) : true)
        .filter(ballot => this.selectedSport ? ballot.sportName === this.selectedSport : true)
        .filter(ballot => this.selectedSeason ? ballot.season === this.selectedSeason : true)
        .filter(ballot => this.selectedType ? ballot.ballotTypeName === this.selectedType : true)
    },
  },

  methods: {
    formatDate(date) {
      return date == null
      ? "N/A"
      // : moment(date).format("YYYY-MM-DD hh:mm A")
      : moment(date).format("MM/DD/YY hh:mm A")
    },

    clickNominate(ballot) {
      this.$router.push({ name: "SelectAward", params: { ballotId: ballot.id } })
    },

    clickVote(ballot) {
      this.$router.push({ name: "Vote", params: { ballotId: ballot.id } })
    }
  },

  async created() {
    this.$root.updateTitle("Ballots")
    
    let sports
    let ballotTypes
    let ballotPhases
    let ballots
    await Promise.all([
      this.$store.getters.conferenceSports.then(_ => sports = _),
      this.$store.getters.ballotTypes.then(_ => ballotTypes = _),
      this.$store.getters.ballotPhases.then(_ => ballotPhases = _),
      Controllers.BallotController.ListBallots().then(res => ballots = res.data),
    ])
      .then(() => {
        ballots.forEach(ballot => {
          Controllers.BallotController.interpretDatesAsUtc(ballot)
          ballot.ballotTypeName = ballotTypes.find(ballotType => ballotType.value === ballot.ballotType).name
          const sport = sports.find(sport => sport.globalSportId === ballot.globalSportId)
          ballot.sportName = sport ? sport.name : ""

          ballot.userAlreadyVoted = ballot.awards.map(a => a.results).flat().length > 0

          ballot.currentPhase = {
            value: Controllers.BallotController.GetCurrentBallotPhase(ballot)
          }
          ballot.currentPhase.name = ballotPhases.find(ballotPhase => ballotPhase.value == ballot.currentPhase.value).name

          // if(!ballot.phaseManuallyOverriden) {
            ballot.expirationDate = ballot.currentPhase.value == 2
              ? ballot.nominationPhaseEndDate
              : ballot.votingPhaseEndDate
          // }
        })

        this.ballots = ballots
      })

      this.loaded = true
  }
}
</script>

<style>

</style>